import type { Asset, CustomColor, EdgeStyles, Palette } from './shared'
import { normalizeBackgroundColor } from '~/lib/storyblok'

export interface HeroPlpBlock {
  component: 'hero-plp-block'
  edge: EdgeStyles
  body: string
  additionalText: string
  image: Asset
  imageAltText: string
  background_color: (Palette | CustomColor)[]
  font_color: string
}

export function normalizePlpHero(blok?: HeroPlpBlock) {
  return {
    body: blok?.body,
    additionalText: blok?.additionalText,
    edge: blok?.edge,
    image: blok?.image.filename,
    imageAltText: blok?.imageAltText,
    backgroundColor: normalizeBackgroundColor(blok?.background_color?.[0]),
    fontColor: blok?.font_color ?? 'black',
  }
}
