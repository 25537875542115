import type { StoryblokContent } from '../storyblok'
import type { Asset, CTALabelCampaign, CTALabelCategory, CustomColor, EdgeStyles, Link, Palette } from './shared'
import { normalizeBackgroundColor } from '~/lib/storyblok'

export interface ProductListMediaCard extends StoryblokContent {
  component: 'product-list-media-card'
  background_color: (Palette | CustomColor)[]
  font_color: string
  body: string
  cta_link: Link
  cta_label: (CTALabelCampaign | CTALabelCategory)[]
  asset: Asset
  alt_text: string
  edge_style: EdgeStyles
}

export interface NormalizedProductListMediaCard {
  ctaLink: string
  ctaLabel: string
  body: string
  asset: string
  altText: string
  edgeStyle: 'none' | 'teeth' | 'scallop' | 'wave'
  backgroundColor: string
  fontColor: string
}

export function normalizeProductListMediaCard(blok: ProductListMediaCard): NormalizedProductListMediaCard {
  function normalizeCTALabel(CTALabel?: CTALabelCampaign | CTALabelCategory): string {
    if (CTALabel?.component === 'cta-label-category')
      return CTALabel.cta_label
    else if (CTALabel?.component === 'cta-label-campaign')
      return CTALabel.cta_label
    else
      return ''
  }

  return {
    ctaLink: blok?.cta_link?.cached_url,
    ctaLabel: normalizeCTALabel(blok?.cta_label?.[0]),
    body: blok?.body,
    asset: blok?.asset.filename,
    altText: blok?.alt_text,
    edgeStyle: blok?.edge_style ?? 'none',
    backgroundColor: normalizeBackgroundColor(blok?.background_color?.[0], true),
    fontColor: blok?.font_color,
  }
}
